

// ------------ A C U E R D O S    I T E ------------
export const dataAcuerdos2025 = [
  {
    id: 1,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "SE REDECUA DISTRIBUCION DE PRERROGATIVAS A PP",
    titleAnexo1: "FINANCIAMIENTO PUBLICO ANUAL",
    titleAnexo2: "FINANCIAMIENTO PUB ACT ESPECIFICAS",
  },
  {
    id: 2,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "ACTUALIZACION Y CANLENDARIZACION DE MULTAS PP",
    titleAnexo1: "ACTUALIZACION CALENDARIZACION SANCIONES",
    titleAnexo2: "CALENDARIZADO DE SANCIONES",
  },
  {
    id: 3,
    typeDoc: "RESOLUCION",
    monthDoc: "ENE",
    nameDoc:
      "MODIFICACION DE DOCUMENTOS BASICOS FXMT",
  },
  {
    id: 4,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "SE APRUEBA EL PROGRAMA DE IMPARTICIÓN ELABORADO POR LA JUNTA GENERAL EJECUTIVA",
    titleAnexo1: "PROGRAMA DE IMPARTICIÓN DE SEMINARIOS, CONFERENCIAS, COLOQUIOS, DIPLOMADOS, MESAS REDONDAS, DIVULGACIÓN DE TEXTOS, PUBLICACIONES DE RESULTADOS DE INVESTIGACIÓN Y PRESENTACIÓN DE LIBROS 2025",
  },
  {
    id: 5,
    typeDoc: "RESOLUCION",
    monthDoc: "ENE",
    nameDoc:
      "RESOLUCIÓN DEL CONSEJO GENERAL DEL INSTITUTO TLAXCALTECA ELECCIONES, DENTRO DEL PROCEDIMIENTO ORDINARIO SANCIONADOR CON NÚMERO DE EXPEDIENTE CQD/Q/IML/CG/076/2024",
  },
  {
    id: 6,
    typeDoc: " REFORMA EL REGLAMENTO DE SESIONES DEL CONSEJO GENERAL DEL INSTITUTO TLAXCALTECA DE ELECCIONES.",
    monthDoc: "FEB",
    nameDoc:
      "REFORMA DEL REGLAMENTO DE SESIONES DEL CG DEL ITE",
      titleAnexo1: "REGLAMENTO DE SESIONES DEL CG DEL ITE",
  },
  {
    id: 7,
    typeDoc: "PROYECTO DE PRESUPUESTO PARA EL PROCESO EXTRAORDINARIO 2024-2025 ",
    monthDoc: "FEB",
    nameDoc: " EL PROYECTO DE PRESUPUESTO PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO PARA RENOVAR LOS CARGOS DEL PODER JUDICIAL, TRIBUNAL DE JUSTICIA ADMINISTRATIVA Y TRIBUNAL DE CONCILIACIÓN Y ARBITRAJE 2024-2025.",
      titleAnexo1: "AMPLIACION PRESUPUESTAL",
  },
 
];

// ------------ A C U E R D O S    I N E ------------
export const dataAcuerdosINE2025 = [
 /*  {
    id: 1,
    typeDoc: "ACUERDO",
    numDoc: "INE/CG2267/2025",
    nameDoc:
      "NOMBRE DEL DOCUMENTO",
  }, */

];
