import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import TitlePages from "../../layout/TitlePages";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../../layout/Breadcrumbs";
import Expandible from "../../layout/HelperDataTable/Expandible";
import { FilterComponent, useCompleteTableConfig } from "../../constants";
import { dataAcuerdos2025} from "../../data/2025/dataAcuerdos";
import { dataEstrados } from "../../data/2025/dataEstradosConvocatorias";
import { dataAmonestaciones } from "../../data/2025/dataEstradosConvocatorias";

const baseUrlAcuerdos = "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2025/";

const PdfLink = ({ url, baseUrl }) => {
  const fullUrl = `${baseUrl}${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const TableRow = ({ title, url, baseUrl }) =>
  title && url ? (
    <tr>
      <td>{title.toUpperCase()}</td>
      <td>
        <PdfLink url={url} baseUrl={baseUrl} />
      </td>
    </tr>
  ) : null;

const EstradosElectronicos = () => {
  useEffect(() => {
    document.title = `Estrados Electrónicos`;
  }, []);

  const columnsEstrados = useMemo(
    () => [
      {
        accessorFn: (row) => `MEDIO DE IMPUGNACIÓN 0${row.numCedula}`,
        id: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
        size: 255,
      },
    ],
    []
  );

  const columnsAmonestaciones = useMemo(
    () => [
      {
        accessorFn: (row) => `AMONESTACIÓN PUBLICA ITE-CG ${row.numCedula}-2025`,
        id: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
        size: 255,
      },
    ],
    []
  );

  const renderDetailPanelEstrados = ({ row }) => {
    const { numCedula } = row.original;

    const generateLabel = (suffix) => {
      const label = suffix === "" ? "CÉDULA" : "FOLIO";
      const yearSuffix = suffix ? "-2025" : "";
      return `${label} 0${numCedula}${yearSuffix}`;
    };

    const generatePdfUrl = (suffix) => `${numCedula}${suffix}.pdf`;

    return (
      <Box id="Box">
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered table align-middle w-50">
            <thead></thead>
            <tbody>
              {["", ".1"].map((suffix) => (
                <tr
                  key={suffix}
                  className={suffix === "" ? "table-secondary" : ""}
                >
                  <td>{generateLabel(suffix)}</td>
                  <td>
                    <PdfLink
                      baseUrl="https://itetlax.org.mx/assets/pdf/estrados/"
                      url={generatePdfUrl(suffix)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>
    );
  };

  const renderDetailPanelAmonestaciones = ({ row }) => {
    const { numCedula } = row.original;

    const generateLabel = (suffix) => {
        const label = "RESOLUCIÓN"; // Cambiamos esto para que siempre sea "AMONESTACION"
        
        return `${label}`;
    };

    const generatePdfUrl = (suffix) => `${numCedula}${suffix}.pdf`;

    return (
        <Box id="Box">
            <div className="table-responsive">
                <table className="table table-hover table-sm table-bordered table align-middle w-50">
                    <thead></thead>
                    <tbody>
                        {/* Solo se mapea una vez */}
                        <tr className="table-secondary">
                            <td>{generateLabel("")}</td>
                            <td>
                                <PdfLink
                                    baseUrl="https://itetlax.org.mx/assets/pdf/amonestaciones/"
                                    url={generatePdfUrl("")}
                                />
                            </td>
                        </tr>
                        {/* Si necesitas la segunda amonestación, puedes descomentar el siguiente bloque */}
                        {/* <tr>
                            <td>{generateLabel(".1")}</td>
                            <td>
                                <PdfLink
                                    baseUrl="https://itetlax.org.mx/assets/pdf/amonestaciones/"
                                    url={generatePdfUrl(".1")}
                                />
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </Box>
    );
};


  const tableEstrados = useCompleteTableConfig(
    dataEstrados,
    columnsEstrados,
    renderDetailPanelEstrados
  );

  const tableAmonestaciones = useCompleteTableConfig(
    dataAmonestaciones,
    columnsAmonestaciones,
    renderDetailPanelAmonestaciones
  );

  const columnsAcuerdos = useMemo(
    () => [
      {
        accessorKey: "monthDoc",
        header: "MES",
        footer: "MES",
        size: 30,
        Filter: FilterComponent,
      },
      {
        accessorFn: (row) => `ITE-CG ${row.id}-2025`,
        id: "acuerdo",
        header: "ACUERDO",
        footer: "ACUERDO",
        size: 55,
      },
      {
        accessorFn: (row) =>
          row.nameDoc ? `${row.typeDoc} ${row.nameDoc}` : "",
        id: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
        size: 255,
      },
    ],
    []
  );

  const renderDetailPanelAcuerdos = ({ row }) => {
    const anexoTitles = Object.keys(row.original).filter(
      (key) => key.startsWith("titleAnexo") && row.original[key]
    );

    return (
      <Box id="Box">
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered table align-middle w-40">
            <thead>
              <tr>
                <td colSpan={2}>
                  <br />
                  <strong>A C U E R D O</strong>
                  <br />
                  <br />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="table-secondary">
                <td>
                  {`${row.original.typeDoc} ITE-CG ${row.original.id}-2025 ${
                    row.original.nameDoc || ""
                  }`}
                </td>
                <td>
                  {row.original.id && (
                    <PdfLink
                      baseUrl={baseUrlAcuerdos}
                      url={row.original.id + ".pdf"}
                    />
                  )}
                </td>
              </tr>
              {anexoTitles.length > 0 && (
                <tr>
                  <td colSpan={2}>
                    <br />
                    <strong>A N E X O</strong>
                    <br />
                    <br />
                  </td>
                </tr>
              )}
              {anexoTitles.map((key) => {
                const index = key.replace("titleAnexo", "");
                return (
                  <TableRow
                    key={index}
                    title={row.original[key]}
                    baseUrl={baseUrlAcuerdos}
                    url={`${row.original.id}.${index}.pdf`}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </Box>
    );
  };

  const tableAcuerdos = useCompleteTableConfig(
    dataAcuerdos2025,
    columnsAcuerdos,
    renderDetailPanelAcuerdos
  );

  return (
    <>
      <Breadcrumbs path={[{ label: "Estrados Electrónicos" }]} />
      <TitlePages title="Estrados Electrónicos" subTitle="" />
      <Expandible />

      <div className="w-75 mx-auto mt-5">
        <TitlePages title="" subTitle="Medios de impugnación" />
      </div>
      <MaterialReactTable table={tableEstrados} />
      <br />
      <div className="w-75 mx-auto mt-5">
        <TitlePages title="" subTitle="Amonestaciones Publicas" />
      </div>
      <MaterialReactTable table={tableAmonestaciones} />
      <br />
      <br />
      <div className="w-75 mx-auto mt-5">
        <TitlePages title="" subTitle="Acuerdos 2025" />
      </div>
      <MaterialReactTable table={tableAcuerdos} />
      <br />
      <br />
    </>
  );
};

export default EstradosElectronicos;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

TableRow.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
