import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import SinExpandir from "../../../layout/HelperDataTable/SinExpandir";
import ListBadge from "../../../layout/ListBadge";
import { FilterYear, useSimpleTableConfig } from "../../../constants";
import { dataInfContable } from "../../../data/dataTransparencia";
import { dataCuentaPublicaNew } from "../../../data/2024/dataCuentaPublica";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import {
  dataInfFinanciera,
  dataInfo2016,
} from "../../../data/dataTransparencia";

const baseUrl = "https://itetlax.org.mx/assets/pdf/transparencia/infContable";

const PdfLink = ({ url }) => {
  const fullUrl = `${baseUrl}/${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
};

const Accordion = ({ idAccordion, clasName, children }) => {
  return (
    <div className={`accordion ${clasName}`} id={idAccordion}>
      {children}
    </div>
  );
};

const AccordionItem = ({
  flushID,
  titleItem,
  contentItem,
  isOpen,
  toggleItem,
}) => {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading-${flushID}`}>
        <button
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={toggleItem}
          aria-expanded={isOpen}
          aria-controls={`collapse-${flushID}`}
        >
          {titleItem}
        </button>
      </h2>
      <div
        id={`collapse-${flushID}`}
        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={`heading-${flushID}`}
      >
        <div className="accordion-body">{contentItem}</div>
      </div>
    </div>
  );
};

const InformacionContable = () => {
  const [data, setData] = useState([]);
  const [openItems, setOpenItems] = useState({}); 

  useEffect(() => {
    document.title = "Información Contable y Financiera";
  }, []);

  useEffect(() => {
    if (dataCuentaPublicaNew.length > 0) {
      setData(dataCuentaPublicaNew);
    }
  }, []);


  const toggleItem = (id) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "id",
        header: "ID",
        footer: "ID",
        enableResizing: false,
        size: 80,
      },
      {
        accessorKey: "year",
        header: "AÑO",
        footer: "AÑO",
        enableResizing: false,
        Filter: FilterYear,
        size: 80,
      },
      {
        accessorFn: (row) => `${row.title} ${row.year}`,
        id: "title",
        header: "NOMBRE",
        footer: "NOMBRE",
        size: 200,
      },
      {
        accessorKey: "link",
        header: "",
        footer: "",
        Cell: ({ cell }) => <PdfLink url={cell.getValue() + ".pdf"} />,
        size: 100,
      },
    ];
  }, []);

  return (
    <>
      {/* Sección de Información Contable */}
      <Breadcrumbs
        path={[
          { label: "Transparencia", url: "/Transparencia" },
          { label: "Información Contable y Financiera" },
        ]}
      />
      <TitlePages title="Transparencia" subTitle="Información Contable" />
      <ListBadge
        ifNumbered=""
        listsBadgeItem={dataInfContable}
        clasName="w-75 mx-auto"
      />
      <br />
      <br />
      <TitlePages title="" subTitle="Cuenta Pública" />
      <SinExpandir />

      <div
        style={{ overflowX: "auto", maxWidth: "100%", marginBottom: "60px" }}
      >
        <MaterialReactTable
          columns={columns}
          data={data}
          enablePagination={true}
          initialState={{ pagination: { pageSize: 5 } }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [10, 25, 50, 100],
            showFirstButton: true,
            showLastButton: true,
          }}
          localization={MRT_Localization_ES}
        />
      </div>

      {/* Sección de Información Financiera */}

      <TitlePages title="" subTitle="Información Financiera" />
      <ListBadge
        ifNumbered={""}
        listsBadgeItem={dataInfFinanciera}
        clasName="w-75 mx-auto"
      />
      <br />

      <div
        style={{ overflowX: "auto", maxWidth: "100%", marginBottom: "100px" }}
      >
        <Accordion
          idAccordion="informacionFinanciera"
          clasName={"w-75 mx-auto"}
        >
          {dataInfo2016.map((infoAccordion) => (
            <AccordionItem
              key={infoAccordion.id}
              flushID={infoAccordion.flushID}
              titleItem={infoAccordion.titleItem}
              contentItem={
                <ListBadge
                  ifNumbered={""}
                  listsBadgeItem={infoAccordion.children}
                />
              }
              isOpen={openItems[infoAccordion.id]} 
              toggleItem={() => toggleItem(infoAccordion.id)} 
            />
          ))}
        </Accordion>
      </div>
    </>
  );
};

export default InformacionContable;