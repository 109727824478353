export const dataCIndex = [
  {
    id: 1,
    imgUrl: "personas_extranjeras.jpeg",
    alt: "personas_extranjeras",
    url: "",
    linkToo: "",
  },
  {
    id: 2,
    imgUrl: "libroITE.jpg",
    alt: "Libro ITE",
    url: "https://itetlax.org.mx/assets/pdf/carousel/libroITE.pdf",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "libroOPPMT.jpg",
    alt: "Libro OPPMT",
    url: "https://itetlax.org.mx/assets/pdf/carousel/libroOPPMT.pdf",
    linkToo: "",
  },

  {
    id: 4,
    imgUrl: "comunidades.jpeg",
    alt: "micrositioComunidades",
    url: "https://ite-comunidades.itetlax.org.mx",
    linkToo: "",
  },
  {
    id: 5,
    imgUrl: "eleccionjudiciallocal.png",
    alt: "Elección Judicial",
    itemActive: "active",
    buttonActive: "active",
    url: "",
    linkToo: "",
  },
];

export const dataCAR = [
  {
    id: 1,
    imgUrl: "16_par_inf_tlax_2025.jpeg",
    itemActive: "active",
    buttonActive: "active",
    alt: "Consulta Infantil",
    url: "https://congresodetlaxcala.gob.mx/wp-content/uploads/2024/11/pi2025.pdf",
    linkToo: "",
  },
  {
    id: 2,
    imgUrl: "0.png",
    alt: "Convocatoria OPPMT",
    url: "",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "11.png",
    alt: "siceen21",
    url: "https://siceen21.ine.mx/home",
    linkToo: "",
  },
  {
    id: 4,
    imgUrl: "apoyo_ciudadano.png",
    alt: "Convocatoria",
    url: "",
    linkToo: "",
  },
  {
    id: 5,
    imgUrl: "infografia-paridad.png",
    alt: "Convocatoria",
    url: "",
    linkToo: "",
  },
  {
    id: 6,
    imgUrl: "como_votar.jpg",
    alt: "Como votar",
    url: "",
    linkToo: "",
  },
  {
    id: 7,
    imgUrl: "encarte.jpg",
    alt: "Encarte",
    url: "https://ine.mx/wp-content/uploads/2024/11/PELE24_TLAX_Listado-ubicacion-Integracion-casillas.pdf",
    linkToo: "",
  },
  {
    id: 8,
    imgUrl: "001._Cartel principal.png",
    alt: "Encarte",
    url: "https://aplicaciones.iecm.mx/votafilmfest2024/index.php",
    linkToo: "",
  },
  {
    id: 9,
    imgUrl: "OBSERVACIONELECTORAL2025.png",
    alt: "OBSERVACIÓN ELECTORAL",
    url: "https://itetlax.org.mx/assets/pdf/convocatorias/CONVOCATORIA-OBSERVACION-ELECTORAL-2025.pdf",
    linkToo: "",
  },
];

export const dataCA = [
  {
    id: 1,
    imgUrl: "7.jpg",
    alt: "Participación Política de las mujeres tlaxcaltecas",
    itemActive: "active",
    buttonActive: "active",
    url: "",
    linkToo: "",
  },
  {
    id: 2,
    imgUrl: "46.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "47.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 4,
    imgUrl: "48.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 5,
    imgUrl: "49.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 6,
    imgUrl: "50.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
];
