import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import Expandible from "../../../layout/HelperDataTable/Expandible";
import { useCompleteTableConfig } from "../../../constants";
import { dataAcuerdosINE2025 } from "../../../data/2025/dataAcuerdos";

const baseUrl = "https://itetlax.org.mx/assets/pdf/acuerdos/INE/2025";

const FileLink = ({ baseUrl, url, type }) => {
  const icon = type === "pdf" ? faFilePdf : faFileExcel;
  const className = type === "pdf" ? "btn btn-danger" : "btn btn-success";
  const fullUrl = `${baseUrl}/${url}`;

  return (
    <a className="link" href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={icon} className={className} />
    </a>
  );
};

const TableRow = ({ title, url, type }) => {
  return title && url ? (
    <tr>
      <td>{title.toUpperCase()}</td>
      <td>
        <FileLink baseUrl={baseUrl} url={url} type={type} />
      </td>
    </tr>
  ) : null;
};

const AcuerdosINE2025 = () => {
  useEffect(() => {
    document.title = `Acuerdos INE 2025`;
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "numDoc",
        header: "DOCUMENTO",
        footer: "DOCUMENTO",
      },
      {
        accessorKey: "nameDoc",
        header: "DESCRIPCIÓN",
        footer: "DESCRIPCIÓN",
        muiTableBodyCellProps: {
          sx: {
            whiteSpace: "normal",
            wordWrap: "break-word",
            maxWidth: "none",
            width: "auto",
          },
        },
      },
    ],
    []
  );

  const renderDetailPanelAcuerdos = ({ row }) => (
    <Box id="Box">
      <div className="table-responsive">
        <table className="table table-hover table-sm table-bordered table align-middle text-wrap">
          <thead>
            <tr>
              <td colSpan={2}>
                <br />
                <strong>A C U E R D O</strong>
                <br />
                <br />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="table-secondary">
              <td>
                {row.original.numDoc} {row.original.nameDoc || ""}
              </td>
              <td>
                <FileLink
                  baseUrl={baseUrl}
                  url={row.original.id + ".pdf"}
                  type="pdf"
                />
              </td>
            </tr>
            {[...Array(70)].map((_, i) => {
              const index = i + 1;
              const titlePDF = row.original[`titleAnexoPDF${index}`];
              const titleExcel = row.original[`titleAnexoEXCEL${index}`];

              return (
                <React.Fragment key={index}>
                  {titlePDF && (
                    <TableRow
                      title={titlePDF}
                      url={`${row.original.id}.${index}.pdf`}
                      type={"pdf"}
                    />
                  )}
                  {titleExcel && (
                    <TableRow
                      title={titleExcel}
                      url={`${row.original.id}.${index}.xlsx`}
                      type={"excel"}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </Box>
  );

  const tableAcuerdos = useCompleteTableConfig(
    dataAcuerdosINE2025,
    columns,
    renderDetailPanelAcuerdos
  );

  return (
    <>
      <div className="margin-bottom-2">
        <Breadcrumbs
          path={[
            { label: "Acuerdos INE", url: "/AcuerdosINE" },
            { label: "Acuerdos INE 2025" },
          ]}
        />
        <TitlePages title="Acuerdos INE" subTitle="Acuerdos INE 2025" />
        <Expandible />
        <MaterialReactTable table={tableAcuerdos} />
      </div>
    </>
  );
};

export default AcuerdosINE2025;

FileLink.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["pdf", "excel"]).isRequired,
};

TableRow.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["pdf", "excel"]).isRequired,
};
