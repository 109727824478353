export const dataAmonestaciones = [ 
// bajar el 02 de abril
  {
    id: 1,
    numCedula: 5,
  },
 
];


export const dataEstrados = [
  
 
  /* // bajar el 02 de abril
  {
    id: 1,
    numCedula: 5,
  },
 */
];

export const dataConvocatorias = [
  // {
  //   id: 1,
  //   nameFile: "Convocatoria-Licitacion-Publica-Nacional-ITE-01-2024",
  //   linkFile:
  //     "https://itetlax.org.mx/assets/pdf/convocatorias/Convocatoria-Licitacion-Publica-Nacional-ITE-01-2024.pdf",
  // },
];

export const dataConvocatoriasApartado = [
  {
    id: 1,
    nameFile: "Convocatoria Licitacion ITE-LP-06-2024",
    linkFile:
      "https://itetlax.org.mx/assets/pdf/convocatorias/Convocatoria-ITE-LP-06-2024.pdf",
  },
];
