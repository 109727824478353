const baseUrl = "https://itetlax.org.mx/assets/img/";

export const modalsInitData = [
/* {
  id: 1,
  imgLink: "https://itetlax.org.mx/assets/img/imgPopup/Ordendel dia1302.png",
  url: "",
  linkToo: "",
},  */
];

const ModalComponent = () => {
  return (
    <div>
      {modalsInitData.map((modal) => (
        <div key={modal.id}>
          <img 
            src={modal.imgLink} 
            alt="Modal" 
            style={{ maxWidth: '100%', maxHeight: '400px', width: 'auto', height: 'auto' }} // Establece el tamaño máximo aquí
          />
        </div>
      ))}
    </div>
  );
};

export default ModalComponent;